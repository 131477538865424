/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import { StaticMap, NavigationControl } from 'react-map-gl'
import DeckGL, { PolygonLayer } from 'deck.gl'
import axios from 'axios'

// Set your mapbox token here
const MAPBOX_TOKEN = process.env.MAPBOX_API_KEY // eslint-disable-line

// const INITIAL_VIEW_STATE = {};

export default function UrbanCentersMap() {
  const [data, setData] = useState()
  const [viewState, setViewState] = useState({
    longitude: -104.9903,
    latitude: 39.7392,
    zoom: 8,
    pitch: 0,
    bearing: 0,
  })

  const [toolTip, setToolTip] = useState()

  function _renderTooltip() {
    const { hoveredObject, pointerX, pointerY } = toolTip || {}
    return (
      hoveredObject && (
        <div
          className="bg-black-darkest text-white p-3"
          style={{
            opacity: '.95',
            position: 'absolute',
            zIndex: 1,
            pointerEvents: 'none',
            left: pointerX,
            top: pointerY,
          }}
        >
          <p>Name: {hoveredObject.properties.name}</p>
          <p>Community: {hoveredObject.properties.community}</p>
          <p>Acres: {hoveredObject.properties.acres}</p>
        </div>
      )
    )
  }

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        'https://gisdata.drcog.org:8443/geoserver/DRCOGPUB/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=DRCOGPUB:urban_centers&outputFormat=application%2Fjson&srsName=epsg:4326'
      )
      setData(result.data.features)
    }
    fetchData()
  }, [])

  function _onViewStateChange({ viewState }) {
    // console.log(viewState);
    setViewState({ ...viewState, minZoom: 8, maxZoom: 16 })
  }

  return (
    <DeckGL
      controller={{ dragRotate: false, scrollZoom: false }}
      viewState={viewState}
      onViewStateChange={_onViewStateChange}
    >
      <PolygonLayer
        id="polygon-layer"
        data={data}
        pickable
        stroked={false}
        filled
        wireframe
        lineWidthMinPixels={1}
        getPolygon={d => d.geometry.coordinates[0]}
        getFillColor={[153, 102, 204, 200]}
        highlightColor={[255, 153, 51, 180]}
        autoHighlight
        getLineWidth={1}
        onHover={d =>
          setToolTip({
            hoveredObject: d.object,
            pointerX: d.x,
            pointerY: d.y,
          })
        }
      />
      <StaticMap
        reuseMaps
        mapStyle="mapbox://styles/mapbox/light-v10"
        mapboxApiAccessToken={MAPBOX_TOKEN}
        mapOptions={{
          customAttribution:
            'Map data <a href="https://www.drcog.org/">© DRCOG</a>',
          maxBounds: [
            [-108.6553, 37.7841],
            [-101.4583, 41.8097],
          ],
        }}
      >
        <div className="mapboxgl-ctrl-top-left">
          <NavigationControl
            showCompass={false}
            // eslint-disable-next-line no-shadow
            onViewStateChange={_onViewStateChange}
          />
        </div>
      </StaticMap>
      {_renderTooltip()}
      {/* Make sure tooltips are the last rendered item */}
    </DeckGL>
  )
}
