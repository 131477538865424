import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import SVGButton from '../components/SVG/SVGButton'
import Layout from '../components/Layout'
import DiscoverMore from '../components/DiscoverMore'
import Breadcrumb from '../components/Breadcrumb'
import Banner from '../components/Banner'
import UrbanCentersMap from '../components/UrbanCentersMap'
import Section from '../components/Section'
import SectionWithAside from '../components/SectionWithAside'
import Container from '../components/Container'

export const InPracticePage3Template = ({
  bannerimage,
  title,
  theme,
  sectionwithaside,
  secondsection,
  thirdsection,
  displaymap,
  discovermore,
}) => (
  <>
    <Banner bannerImage={bannerimage} title={title} theme={theme} />
    <Breadcrumb to="in-practice" label="In Practice" />
    <Container className="pb-10 md:pb-0 pt-12 sm:pt-16 md:pt-20">
      <SectionWithAside content={sectionwithaside} />
    </Container>
    <Container className="flex">
      <Section content={secondsection} />
    </Container>
    <Container className="flex justify-center py-12 sm:py-16 md:py-20">
      {displaymap ? (
        'Map not displayed during preview.'
      ) : (
        <div className="relative w-full mx-20" style={{ height: '600px' }}>
          <UrbanCentersMap />
        </div>
      )}
    </Container>
    <Container outerClassName="py-16 bg-teal">
      <Section
        titleStyle="text-white"
        bodyStyle="text-white"
        content={thirdsection}
      />
    </Container>
    <Container
      outerClassName="bg-black-discovermore text-center"
      className="pb-2 py-10 sm:py-12 md:py-16"
    >
      <DiscoverMore content={discovermore} />
    </Container>
  </>
)

InPracticePage3Template.propTypes = {
  bannerimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  displaymap: PropTypes.string,
  title: PropTypes.string,
  theme: PropTypes.string,
  sectionwithaside: PropTypes.object,
  secondsection: PropTypes.object,
  thirdsection: PropTypes.object,
  discovermore: PropTypes.object,
}

const InPracticePage3 = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout
      ogtitle={frontmatter.ogtitle}
      ogdescription={frontmatter.ogdescription}
      ogimage={frontmatter.ogimage}
    >
      <InPracticePage3Template
        bannerimage={frontmatter.bannerimage}
        title={frontmatter.title}
        theme={frontmatter.theme}
        sectionwithaside={frontmatter.sectionwithaside}
        secondsection={frontmatter.secondsection}
        thirdsection={frontmatter.thirdsection}
        discovermore={frontmatter.discovermore}
      />
    </Layout>
  )
}

InPracticePage3.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default InPracticePage3

export const pageQuery = graphql`
  query InPracticePage3Template($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        opengraph {
          ogtitle
          ogdescription
          ogimage {
            childImageSharp {
              fluid(maxWidth: 300, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        title
        theme
        bannerimage {
          alt
          desktopimage {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          mobileimage {
            childImageSharp {
              fluid(maxWidth: 768, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        sectionwithaside {
          title
          body
          asidetitle
          asidebody
        }
        secondsection {
          title
          body
        }
        thirdsection {
          title
          body
        }
        discovermore {
          title
          body
          link
          linklabel
        }
      }
    }
  }
`
